const $offices = $('.contact-offices');
const $officeModal = $('#popup-modal.office-modal');
const $closeButton = $officeModal.find('a.close');
const $backButton = $officeModal.find('a.back-button');

export const markerClick = function(slug) {
    let $officeInfo = $('.office-wrapper#' + slug).html();
    $officeModal.find(".inner").append($officeInfo);
    var $bgIcons = $('#content-section .icon-wrapper');
    $bgIcons.each(function(index) {
        if(index < 2) {
            $officeModal.append($bgIcons.eq(index).clone());
        }
    });
    $('.icon-wrapper').each(function() {
        $(this).addClass('animate');
    });
    showOfficeModal();
}

function showOfficeModal() {
    $closeButton.insertBefore($officeModal.find('.inner .info').find('.office-name'));
    $backButton.insertAfter($officeModal.find('.inner .info').children().last());
    $('body').addClass('no-scroll');
    $officeModal.css('visibility', 'visible');
    setTimeout(function() {
        if($(window).width() < 1024) {
            $officeModal.find('.icon-wrapper').last().css({
                'width': '20vw',
                'height': '20vw',
                'top': '25%',
                'left': '90%'
            }); 
        }
        else {
            $officeModal.find('.icon-wrapper').last().css({
                'width': '8vw',
                'height': '8vw',
                'top': '20%',
            });
        }
    }, 100);
    setTimeout(function () {
        $officeModal.css({
            'opacity': 1
        })
    }, 100);
    setTimeout(function () {
        $('header').addClass('sticky show no-blur');
        $('header').removeClass('dropdown-padding');
    }, 200);
    $('a.close, a.back-button').click(function (e) {
        e.preventDefault();
        hideOfficeModal();
    })
}

function hideOfficeModal() {
    $('body').removeClass('no-scroll');
    $officeModal.css({
        'opacity': 0
    });
    setTimeout(function () {
        $officeModal.css({
            'visibility': 'hidden'
        })
    }, 100);
    setTimeout(function () {
        $officeModal.find(".inner").html('');
    }, 150);
    $officeModal.children('.icon-wrapper').remove();
    $('header').removeClass('sticky show no-blur');
    $('header').addClass('dropdown-padding');
}