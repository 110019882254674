import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

const $news = $('.news .news-wrapper article.tease-post');
const $moreButton = $('.news').find('a.round-button.more-news');
let currentPage = 1;

if($(window).width() >= 1024) {
    $news.hover(function() {
        let $this = $(this);
        $this.css({
            'opacity': '1',
            'transition': 'opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.4s cubic-bezier(0.645, 0.045, 0.355, 1), filter 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)',
            'transform': 'translate(0, 0) scale(1.015)',
            'filter': 'blur(0)'
        });
    }, function() {
        let $this = $(this);
        $('.tease-post.set-opacity').css({
            'opacity': '1',
            'filter': 'blur(0)'
        });
        $this.css({
            'opacity': '1',
            'transform': 'translate(0, 0) scale(1)'
        });
        setTimeout(function() {
            $this.css({
                'transition': 'opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), filter 0.3s cubic-bezier(0.645, 0.045, 0.355, 1)'
            });
        }, 300);
    });
}

if($news.length > 0) {
    $moreButton.click(function(e) {
        e.preventDefault();        
        let year = $('#filter .year.select-input').find('li.selected a').data('slug');
        let company = $('#filter .companies.select-input').find('li.selected a').data('slug');
        currentPage++; // Do currentPage + 1, because we want to load the next page
        
        let data = new FormData();
        data.append('action', 'get_news');
        data.append('company', company);
        data.append('year', year);
        data.append('paged', currentPage);
        $.ajax({
            type: 'POST',
            url: hitecvision_scripts.ajax_url,
            cache: false,
            processData: false,
            contentType: false,
            data: data,
            success: function (res) {
                $('.news-wrapper').append(res);
                createBatch(".news-wrapper .tease-post");
                let $totalPosts = $('#posts-total').data('total');
                if($('.tease-post').length == 0) {
                    $('.news-wrapper').append('<h3 class="no-articles-info">No results</h3>');
                }
                if ($('.news-wrapper .tease-post').length < $totalPosts) {
                    $moreButton.removeClass('hide');
                }
                else {
                    $moreButton.addClass('hide');
                }
            }
        });

    });

    function createBatch(target) {
        ScrollTrigger.batch(target, {
            interval: 0.3, // time window (in seconds) for batching to occur. The first callback that occurs (of its type) will start the timer, and when it elapses, any other similar callbacks for other targets will be batched into an array and fed to the callback. Default is 0.1
            batchMax: 4,   // maximum batch size (targets)
            start: "top-=50 bottom",
            onEnter: (batch) => {
                gsap.to(batch, {display: 'block', y: 0, autoAlpha: 1, stagger: 0.15, overwrite: true});
                for (let i=0; i < batch.length; i++) {
                    batch[i].classList.add('set-opacity');
                }
            },
            ease: 'power1',
            once: true
        });
    }

    createBatch(".news-wrapper .tease-post.show");

    function getNews(company, year) {
        let data = new FormData();
        data.append('action', 'get_news');
        data.append('company', company);
        data.append('year', year);
        data.append('paged', currentPage);

        $.ajax({
            url: hitecvision_scripts.ajax_url,
            type: 'POST',
            data: data,
            cache: false,
            processData: false,
            contentType: false,
            error: function (data) {
                console.error(data);
            },
            complete: function (data) {
                document.querySelector('.news-wrapper').innerHTML = data.responseText;
                createBatch(".news-wrapper .tease-post");
                let $totalPosts = $('#posts-total').data('total');
                if($('.tease-post').length == 0) {
                    $('.news-wrapper').append('<h3 class="no-articles-info">No results</h3>');
                }
                if ($('.tease-post').length < $totalPosts) {
                    $moreButton.removeClass('hide');
                }
                else {
                    $moreButton.addClass('hide');
                }
            }
        });
    }

    $('#filter .select-input .select-options').find('a').click(function (e) {
        e.preventDefault();
        let year = $('#filter .year.select-input').find('li.selected a').data('slug');
        let company = $('#filter .companies.select-input').find('li.selected a').data('slug');
        currentPage = 1;
        getNews(company, year);
    });
}