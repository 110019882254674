import { gsap } from "gsap";

var $activeSelect = $('#filter .select-input .active');
var $selectOptions = $('#filter .select-input .select-options');
var $activeOffice = $('#filter .offices .active');
var $offices = $('#filter .offices');
var $employees = $('.employees .employees-wrapper .tease');
var $news = $('.news .news-wrapper .tease');
var $years = $('#filter .year');
var $companies = $('#filter .companies');
var $activeCompany = $('#filter .companies .active');
var $investments = $('.investments .investments-wrapper .tease');
var $activeCurrent = $('#filter .current .active');
var $articles;
var $moreButton;
let $container;

if ($employees.length > 0) {
    $articles = $employees;
    $container = $('.employees .employees-wrapper');
}
else if ($news.length > 0) {
    $articles = $news;
    $container = $('.news .news-wrapper')
    $moreButton = $('.news').find('a.round-button.more-news');
}
else if ($investments.length > 0) {
    $articles = $investments;
    let margin;
    if ($(window).width() < 1024) {
        margin = 0;
    }
    else {
        margin = $('.investments .investments-wrapper .tease-investment').outerHeight() + 142;
    }
    $container = $('.investments .investments-wrapper');
}

if ($investments.length > 0) {
    $activeCurrent.find('a').click(function (e) {
        e.preventDefault();
        if ($(this).parents('.current').hasClass('selected')) {
            $(this).parents('.current').removeClass('selected');
        }
        else {
            $(this).parents('.current').addClass('selected');
        }
        filterArticles($(this), 'null');
    });
}

$activeSelect.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    var currentOptions = gsap.utils.toArray($this.parents('.active').siblings('.select-options').children('li'));
    $this.parents('.active').siblings('.select-options').slideToggle(500);
    $this.toggleClass('open');
    if ($this.parent().hasClass('options-open')) {
        setTimeout(function () {
            $this.parent().removeClass('options-open');
        }, 500);
        gsap.to(currentOptions, {
            autoAlpha: 0,
            rotateX: '-90deg',
            stagger: 0.1,
            ease: 'power1',
        });
    }
    else {
        $this.parent().addClass('options-open');
        gsap.to(currentOptions, {
            duration: 0.3,
            autoAlpha: 1,
            rotateX: 0,
            stagger: 0.1,
            ease: 'power1',
        });
    }
});

$selectOptions.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    $this.parents('.select-options').siblings('.active').find('span').html($this.html());
    $this.parents('.select-options').css({
        'display': 'none'
    });
    $this.parents('.select-options').children('li.selected').removeClass('selected');
    $this.parent('li').addClass('selected');
    $this.parents('.select-input').find('.active').children('a').removeClass('open');
    $this.parents('.select-input').find('.active').removeClass('options-open');
    filterArticles($this, 'null');
});

var searchText = '';
function filterArticles($this, $text) {
    $this.closest('.select-input').addClass("options-active");
    var $selectedFilters = [];
    var $selectActive = $('#filter .select-input.options-active');

    if ($selectActive.length > 0) {
        var $selectArray = [];
        $selectActive.each(function (index) {
            if ($selectActive.eq(index).find('li.selected a').data('slug') != 'all') {
                var $activeTag = $selectActive.eq(index).find('li.selected a').data('slug');
                $selectedFilters.push($activeTag);
            }
            else {
                var $selected = $selectOptions.eq(index).find('li');
                for (var i = 1; i < $selected.length; i++) {
                    var $selectedSlug = $selected.eq(i).find('a').data('slug')
                    $selectArray.push($selectedSlug);
                }
                $selectedFilters.push($selectArray);
            }
        });
    }
    var $tags = [];
    for (var i = 0; i < $articles.length; i++) {
        for (var j = 0; j < $selectedFilters.length; j++) {
            if (!$.isArray($selectedFilters[j])) {
                if ($selectedFilters[j] != 'all') {
                    let $match = $.inArray($selectedFilters[j], $tags);
                    if ($match === -1) {
                        $tags.push($selectedFilters[j]);
                    }
                }
            }
        }
    }
    if ($('#filter #checkbox-input.current').length > 0 && $('#filter #checkbox-input.current.selected').length == 0) {
        $tags.push('current');
    }

    var $articlesClasses = 'null';
    for (var j = 0; j < $tags.length; j++) {
        $articlesClasses = $tags.join('.');
    }

    if($news.length > 0)  {
        var $shownArticles = $('article.tease-post');
        $articles.removeClass('show');
        $('article.tease').hide();
        filterCheck($shownArticles);
    }
    else if ($articlesClasses != 'null') {
        if (searchText != 'null') {
            var $shownArticles = $('article.tease.' + $articlesClasses);
            $articles.removeClass('show');
            $('article.tease').hide();
            filterCheck($shownArticles);
        }
        else {
            $articles.removeClass('show');
            $('article.tease').hide();
            $('article.tease.' + $articlesClasses).addClass('show');
            $('article.tease.' + $articlesClasses).show();
        }
    }
    else {
        $('article.tease').show();
        $('article.tease').addClass('show');
        if (searchText != 'null') {
            var $shownArticles = $('article.tease.show');
            $articles.removeClass('show');
            $('article.tease').hide();
            filterCheck($shownArticles);
        }
    }
    if ($offices.hasClass('selected')) {
        sortArticles($offices, $sortedOffices);
    }
    if ($companies.hasClass('selected')) {
        sortArticles($companies, $sortedCompanies);
    }
    if ($news.length > 0) {
        let hiddenNews = $('.news .news-wrapper .tease-post:not(.show)');
        hiddenNews.each(function (index) {
            hiddenNews.eq(index).addClass('set-opacity');
            hiddenNews.eq(index).css({
                'opacity': 1,
                'visibility': 'visible',
                'transform': 'translateY(0)'
            });
        });
    }
    if ($investments.length > 0 && $('.investments-filter .current.selected').length > 0) {
        $articles.each(function (index) {
            $articles.eq(index).addClass('set-opacity');
            $articles.eq(index).css({
                'opacity': 1,
                'visibility': 'visible',
                'transform': 'translateY(0)'
            });
        });
    }
}

let textDisplayed = false;
function filterCheck($shownArticles) {
    for (var i = 0; i < $shownArticles.length; i++) {
        if ($shownArticles.eq(i).find('.tease-title').text().toLowerCase().indexOf(searchText) !== -1) {
            $shownArticles.eq(i).addClass('show');
            $shownArticles.eq(i).show();
        }
        if ($shownArticles.eq(i).find('.position').text().toLowerCase().indexOf(searchText) !== -1) {
            $shownArticles.eq(i).addClass('show');
            $shownArticles.eq(i).show();
        }
        if ($shownArticles.eq(i).attr('data-office') && $shownArticles.eq(i).attr('data-office').toLowerCase().indexOf(searchText) !== -1) {
            $shownArticles.eq(i).addClass('show');
            $shownArticles.eq(i).show();
        }
        if ($shownArticles.eq(i).attr('data-company') && $shownArticles.eq(i).attr('data-company').toLowerCase().indexOf(searchText) !== -1) {
            $shownArticles.eq(i).addClass('show');
            $shownArticles.eq(i).show();
        }
        if ($shownArticles.eq(i).attr('data-meta')) {
            var $articleMeta = $shownArticles.eq(i).attr('data-meta');
            $articleMeta.split(',').forEach(function (item, index) {
                if (item.indexOf(searchText) !== -1) {
                    $shownArticles.eq(i).addClass('show');
                    $shownArticles.eq(i).show();
                }
            });
        }
        if($('.investments-wrapper .tease.show').length > 0) {
            gsap.to('.investments-wrapper .tease.show', {
                y: 0, 
                autoAlpha: 1, 
                stagger: 0.15, 
                overwrite: true,
                ease: 'power1'
            });
        }
    }

    if($news.length == 0) {
        if($('.tease.show').length == 0 && !textDisplayed) {
            $container.append('<h3 class="no-articles-info">No results</h3>');
            textDisplayed = true;
        }
        else if ($('.tease.show').length > 0 && textDisplayed) {
            $container.find('.no-articles-info').remove();
            textDisplayed = false;
        }
    }
}

function sortArticles($select, $array) {
    if ($select.hasClass('selected')) {
        for (var i = 0; i < $array.length; i++) {
            $('article.tease.show.' + $array[i]).css('order', i);
        }
    }
    else {
        $('article.tease.show').css('order', 'initial');
    }
}

$('#articles-search').keyup(function (evt) {
    searchText = evt.target.value.toLowerCase();
    filterArticles($(this), searchText);
});
