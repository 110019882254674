var $employees = $('.employees .employees-wrapper .tease');
var $employeesImg = $employees.find('.image');
var $employeeModal = $('#popup-modal.employee-modal');
var $closeButton = $employeeModal.find('a.close');
var $backButton = $employeeModal.find('a.back-button');

$employees.hover(function() {
    $employees.addClass('fade-down');
    $(this).removeClass('fade-down');
    $(this).addClass('hover');
}, function() {
    $employees.removeClass('fade-down');
    $(this).removeClass('hover');
});

$('.employee-card').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    showEmployeeModal($this);
});

function showEmployeeModal(elem) {
    var $employeeInfo = elem.html();    
    $employeeModal.find(".inner").append($employeeInfo);
    var $bgIcons = $('#content-section .icon-wrapper');
    $bgIcons.each(function(index) {
        if(index < 2) {
            $employeeModal.append($bgIcons.eq(index).clone());
        }
    });
    $('.icon-wrapper').each(function() {
        $(this).addClass('animate');
    });
    $closeButton.insertBefore($employeeModal.find('.inner .info').find('.tease-title'));
    $employeeModal.find('.inner .info').append($backButton);
    $('body').addClass('no-scroll');
    $employeeModal.css('visibility', 'visible');
    setTimeout(function() {
        if($(window).width() < 768) {
            $employeeModal.find('.icon-wrapper').first().css({
                'width': '10vw',
                'height': '10vw',
                'top': '9rem',
                'left': '90%'
            }); 
        }
        else if($(window).width() < 1024) {
            $employeeModal.find('.icon-wrapper').first().css({
                'top': '20rem',
                'left': '90%'
            }); 
        }
        else {
            $employeeModal.find('.icon-wrapper').first().css({
                'width': '5vw',
                'height': '5vw',
                'top': '22%',
            });
        }
        $employeeModal.find('.icon-wrapper .bg-icon').css({
            'transform': 'translate(0, 0)'
        });
    }, 100);
    setTimeout(function () {
        $employeeModal.css({
            'opacity': 1
        })
    }, 100);
    setTimeout(function () {
        $('header').addClass('sticky show no-blur');
        $('header').removeClass('dropdown-padding');
        $('header .nav-wrapper').removeClass('show-dropdown');
    }, 200);
    $('a.close, a.back-button').click(function (e) {
        e.preventDefault();
        hideEmployeeModal();
    })
}

function hideEmployeeModal() {
    $('body').removeClass('no-scroll');
    $employeeModal.css({
        'opacity': 0
    });
    setTimeout(function () {
        $employeeModal.css({
            'visibility': 'hidden'
        })
    }, 100);
    setTimeout(function () {
        $employeeModal.find(".inner").html('');
    }, 150);
    $('header').removeClass('show no-blur');
    $('header').addClass('dropdown-padding');
    $('header .nav-wrapper').addClass('show-dropdown');
    $employeeModal.children('.icon-wrapper').remove();
}

if (window.location.hash) {
    var target = window.location.hash,
    target = target.replace('#', '');
    window.location.hash = "";

    $('html,body').animate({
        scrollTop: $("#" + target).offset().top - 64
    },400);
    setTimeout(function () {
        showEmployeeModal($('.employees .tease-employee #' + target));
    },450);
}