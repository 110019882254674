import { gsap } from "gsap";

let $header = $('header');
let $headerHeight = $header.height();
let lastScrollTop = 0;
let $padding;
let $navIcon = $('header .nav-wrapper #nav-icon');
let $searchIcon = $('header .nav-wrapper .search-icon');
let $activeSelect = $('header #menu-select-input .active');
let $selectOptions = $('header #menu-select-input .select-options');
const $dropdownOverlay = $('body').find('.dropdown-overlay');

$navIcon.hover(function() {
    $(this).addClass('hover');
}, function(){
    $(this).removeClass('hover');
});

$searchIcon.hover(function() {
    $(this).addClass('hover');
}, function(){
    $(this).removeClass('hover');
});

if ($(window).width() >= 2000) {
    $padding = 6*16;
}
else if ($(window).width() >= 1600) {
    $padding = 4*16;
}
else if ($(window).width() >= 768){
    $padding = 2*16;
}
else {
    $padding = 0;
}

$(window).scroll(function (e) {
    var scrollTop = $(this).scrollTop();
    if (scrollTop < $padding) {
        $header.removeClass('sticky show animate-header');
    } else if (scrollTop > $headerHeight) {
        if (scrollTop < lastScrollTop) { // Scrolling up
            $header.addClass('sticky animate-header show')
        } else {
            $header.addClass('sticky').removeClass('show');
        }
    }
    lastScrollTop = scrollTop;
});

$(window).resize(function() {
    if ($(window).width() >= 2000) {
        $padding = 6*16;
    }
    else if ($(window).width() >= 1600) {
        $padding = 4*16;
    }
    else if ($(window).width() >= 768){
        $padding = 2*16;
    }
    else {
        $padding = 0;
    }
});

$activeSelect.find('a').click(function (e) {
    e.preventDefault();
    var $this = $(this);
    var currentOptions = gsap.utils.toArray($this.parents('.active').siblings('.select-options').find('li'));
    $this.parents('.active').siblings('.select-options').slideToggle(500);
    $this.toggleClass('open');
    if ($this.parent().hasClass('options-open')) {
        setTimeout(function () {
            $this.parent().removeClass('options-open');
        }, 500);
        gsap.to(currentOptions, {
            autoAlpha: 0,
            rotateX: '-90deg',
            stagger: 0.1,
            ease: 'power1',
        });
    }
    else {
        $this.parent().addClass('options-open');
        gsap.to(currentOptions, {
            duration: 0.3,
            autoAlpha: 1,
            rotateX: 0,
            stagger: 0.1,
            ease: 'power1',
        });
    }
});
if($header.find('#menu-select-input .select-options li').length <= 1) {
    $header.removeClass('dropdown-padding');
    $header.find('.nav-wrapper').removeClass('show-dropdown');
}
if($('.contact-offices').length > 0 && $(window).width() < 1024) {
    $header.removeClass('dropdown-padding');
    $header.find('.nav-wrapper').removeClass('show-dropdown');
}
