import { setTitle } from "./_hero";

let $stories = $('.stories .wrapper article.tease-story');
let $moreButton = $('.stories').find('a.round-button.load-more');
let $relatedStories = $('body.single-story article').find('.related-stories article');

if($stories.length > 0) {
    $stories.each(function(index) {
        setTitle($stories.eq(index).find('h2'));
    });
    $moreButton.click(function(e) {
        e.preventDefault();
        $stories.each(function(index) {
            if(!$stories.eq(index).hasClass('show')) {
                $stories.eq(index).addClass('show');
                setTitle($stories.eq(index).find('h2'));
            }
        });
        $(this).addClass('hide');
    });

    $stories.hover(function () {
        $stories.addClass('fade-down');
        $(this).removeClass('fade-down');
        $(this).addClass('scale');
    }, function () {
        $stories.removeClass('fade-down');
        $(this).removeClass('scale');
    });
}

if($relatedStories.length > 0) {
    $relatedStories.each(function(index) {
        setTitle($relatedStories.eq(index).find('h2'));
    });
  $relatedStories.hover(function () {
        $relatedStories.addClass('fade-down');
        $(this).removeClass('fade-down');
        $(this).addClass('scale');
    }, function () {
        $relatedStories.removeClass('fade-down');
        $(this).removeClass('scale');
    });
}