import './_body-view-height'
import { initMap } from "./_map.js";
window.initMap = initMap;

import './_menu.js';
import './_hero.js';
import './_employees.js';
import './_filter.js';
import './_single.js';
import './_search.js';
import './_offices.js';
import './_investments.js';
import './_scroll-to.js';
import './_news.js';
import './_header.js';
import './_stories.js';
import './_bg-icons.js';
import './_engagements.js';
import './_intersection-observer.js';

const $passwordBlock = $('.password-protected');
if($passwordBlock.length > 0) {
    $passwordBlock.find('input[type="password"]').attr("placeholder", "Password");
    $passwordBlock.find('input[type="password"]').focus();
}
import './_slideshow.js';