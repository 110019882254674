import {gsap} from 'gsap';

if($('#content-section .gsap-anim-wrapper').length > 0) {
    gsap.set('#content-section .gsap-anim-wrapper .anim-container span', {autoAlpha: 0, y: '100%', rotate: '4deg', z: 0});
    if($('#content-section .gsap-anim-wrapper .text-wrapper').length > 0) {
        gsap.set('#content-section .gsap-anim-wrapper .text-wrapper *', {autoAlpha: 0, y: '100%', rotate: '4deg', z: 0});
    }
    if($('#content-section .gsap-anim-wrapper a.round-button').length > 0) {
        gsap.set('#content-section .gsap-anim-wrapper a.round-button', {scale: 0,y: 0, autoAlpha: 1});
        gsap.set('#content-section .gsap-anim-wrapper a.round-button .anim-left', {maxWidth: 0});
        gsap.set('#content-section .gsap-anim-wrapper a.round-button .anim-right', {maxWidth: 0});
    }
}
if($('#content-section .gsap-anim-button').length > 0) {
    gsap.set('#content-section .gsap-anim-button', {scale: 0});
    gsap.set('#content-section .gsap-anim-button .anim-left', {maxWidth: 0});
    gsap.set('#content-section .gsap-anim-button .anim-right', {maxWidth: 0});
}


const buttons = document.querySelectorAll('#content-section .gsap-anim-button');
const heros = document.querySelectorAll('#content-section .hero .images-wrapper');
const wrappers = document.querySelectorAll('#content-section .gsap-anim-wrapper');
const elementObserver = new IntersectionObserver(
    entries => {
        // entries === elements
        return entries.forEach(event => {
            const {target, intersectionRatio, isIntersecting} = event;
            // Call function
            animateVisible(target, intersectionRatio, isIntersecting);
        });
    },
    //trigger only when above threshold
    {threshold: 0.8}
);
for (const button of buttons) {
    elementObserver.observe(button);
}
for (const hero of heros) {
    elementObserver.observe(hero);
}
for (const wrapper of wrappers) {
    elementObserver.observe(wrapper);
}

const animateVisible = (elem, ratio, isIntersecting) => {
    // When the element is in view:
    if (ratio > 0 && isIntersecting) {
        if(elem.classList.contains('faster')) {
            gsap.to(elem.querySelectorAll('.anim-container span'), {
                duration: 1,
                autoAlpha: 1,
                y: 0,
                rotate: 0,
                z: 0,
                stagger: 0.1,
                ease: 'power3.inOut',
            });
            if(elem.querySelector('a.round-button')) {
                gsap.to(elem.querySelector('a.round-button'), {
                    duration: 0.5,
                    scale: 1,
                    delay: 1,
                    ease: 'power1',
                });
                gsap.to(elem.querySelector('a.round-button .anim-left'), {
                    duration: 1,
                    maxWidth: '2rem',
                    delay: 1.25,
                    ease: 'power1',
                });
                gsap.to(elem.querySelector('a.round-button .anim-right'), {
                    duration: 1,
                    maxWidth: '2rem',
                    delay: 1.25,
                    ease: 'power1',
                });
            }
        }
        else if(elem.classList.contains('gsap-anim-button')) {
            gsap.to(elem, {
                duration: 1,
                scale: 1,
                ease: 'power1',
            });
            gsap.to(elem.querySelector('.anim-left'), {
                duration: 1.5,
                maxWidth: '2rem',
                delay: 0.5,
                ease: 'power1',
            });
            gsap.to(elem.querySelector('.anim-right'), {
                duration: 1.5,
                maxWidth: '2rem',
                delay: 0.5,
                ease: 'power1',
            });
        }
        else {
            gsap.to(elem.querySelectorAll('.anim-container span'), {
                duration: 2,
                autoAlpha: 1,
                y: 0,
                rotate: 0,
                z: 0,
                stagger: 0.2,
                ease: 'power3.inOut',
            });
            if(elem.querySelector('.text-wrapper')) {
                gsap.to(elem.querySelector('.text-wrapper *'), {
                    duration: 2,
                    autoAlpha: 1,
                    y: 0,
                    rotate: 0,
                    z: 0,
                    delay: 1.5,
                    stagger: 0.2,
                    ease: 'power3.inOut',
                });
            }
            if(elem.querySelector('a.round-button')) {
                gsap.to(elem.querySelector('a.round-button'), {
                    duration: 1,
                    scale: 1,
                    delay: 1.25,
                    ease: 'power1',
                });
                gsap.to(elem.querySelector('a.round-button .anim-left'), {
                    duration: 1.5,
                    maxWidth: '2rem',
                    delay: 1.75,
                    ease: 'power1',
                });
                gsap.to(elem.querySelector('a.round-button .anim-right'), {
                    duration: 1.5,
                    maxWidth: '2rem',
                    delay: 1.75,
                    ease: 'power1',
                });
            }
        }
    } 
};