var $searchIcon = $('header .main-menu .nav-wrapper .search-icon');
var $search = $('header .nav-wrapper #search');
var $searchInput = $search.find('#search-input');
var $inputValue = $searchInput.val();
var $closeSearch = $search.find('.search-close');
var $body = $('body');
var $header = $('header');
var $searchClose = $search.find('span');

if($searchIcon) {
    $searchIcon.click(function () {
        if($header.hasClass('show-menu')) {
            $header.removeClass('animate');
            setTimeout(function() {
                $header.removeClass('show-menu');
            }, 200);
            $('header').find('.icon-animation').removeClass('show-circles show-cubes show-pyramids');
            $('ul').removeClass('submenu-open');
            $('li').removeClass('active');
        }
        $header.addClass('animate-search');
        setTimeout(function () {
            $header.addClass('show-search');
        }, 100);
        setTimeout(function () {
            $search.addClass('expand');
            $searchInput.focus();
        }, 300);
        $body.addClass('no-scroll');

        if($header.hasClass('dropdown-padding')) {
            setTimeout(function(){
                $('header .main-menu .nav-wrapper').removeClass('show-dropdown');
            }, 100);
        }
        
        if(!$body.hasClass('search')) {
            if($body.hasClass('home')) {
                for (var i=0; i < 3; i++) {
                    $header.append('<div class="icon-wrapper"><div class="bg-icon"></div></div>');
                }
                $('header .icon-wrapper .bg-icon').eq(0).css({
                    'background-image': 'url(/wp-content/themes/grensesnitt/static/shapes/circle-grey.png)'
                });
                $('header .icon-wrapper .bg-icon').eq(1).css({
                    'background-image': 'url(/wp-content/themes/grensesnitt/static/shapes/cube-blue-4.png)'
                });
                $('header .icon-wrapper .bg-icon').eq(2).css({
                    'background-image': 'url(/wp-content/themes/grensesnitt/static/shapes/pyramid-grey-6.png)'
                });
            }
            else {
                var $bgIcons = $('#content-section .icon-wrapper');
                $bgIcons.each(function(index) {
                    if(index < 3) {
                        $header.append($bgIcons.eq(index).clone());
                    }
                });
            }
            $('.icon-wrapper').each(function(index) {
                $(this).addClass('animate');
                if(index == 1) {
                    $(this).addClass('second');
                }
            });
            if($(window).width() < 1024) {
                $header.find('.icon-wrapper').first().css({
                    'top': '20%',
                    'width': '12vw',
                    'height': '12vw',
                })
                $header.find('.icon-wrapper').eq(1).css({
                    'top': '50%',
                    'left': '70%',
                    'width': '20vw',
                    'height': '20vw',
                })
                $header.find('.icon-wrapper').last().css({
                    'top': '95%',
                    'width': '20vw',
                    'height': '20vw',
                })
            }
            else {
                $header.find('.icon-wrapper').first().css({
                    'top': '25%',
                    'width': '8vw',
                    'height': '8vw',
                })
                $header.find('.icon-wrapper').eq(1).css({
                    'top': '50%',
                    'left': '70%',
                    'width': '10vw',
                    'height': '10vw',
                })
                $header.find('.icon-wrapper').last().css({
                    'top': '100%',
                    'width': '13vw',
                    'height': '13vw',
                })
            }
            $header.find('.icon-wrapper .bg-icon').css({
                'transform': 'translate(0, 0)',
            })
            setTimeout(function() {
                $header.find('.icon-wrapper .bg-icon').css({
                    'opacity': 1,
                })
            }, 300);
        }
        if($body.hasClass('search')) {
            $body.removeClass('no-scroll');
        }
    });
}
if($closeSearch) {
    $closeSearch.click(function() {
        $body.removeClass('no-scroll');
        if ($header.hasClass('show-search')) {
            $header.removeClass('animate-search');
            setTimeout(function() {
                $header.removeClass('show-search');
            }, 200);
            $search.removeClass('expand');
            $header.children('.icon-wrapper').remove();
            if($header.hasClass('dropdown-padding') && $('.contact-offices').length > 0 && $(window).width() >= 1024) {
                if($('header .main-menu .nav-wrapper #menu-select-input .select-options li').length > 0) {
                    setTimeout(function() {
                        $('header .main-menu .nav-wrapper').addClass('show-dropdown');
                    }, 200);
                }
            }
            else if($header.hasClass('dropdown-padding') && !$body.hasClass('home')) {
                if($('header .main-menu .nav-wrapper #menu-select-input .select-options li').length > 0) {
                    setTimeout(function() {
                        $('header .main-menu .nav-wrapper').addClass('show-dropdown');
                    }, 200);
                }
            }
        }
        $searchInput.val('');
    })
}

if($body.hasClass('search')) {
    $header.addClass('show-search results-page-search');
    $search.addClass('expand');
    $searchInput.focus();
}