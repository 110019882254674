import { setTitle } from "./_hero";

const $engagements = $('.engagements .wrapper article.tease-engagement');
const $moreButton = $('.engagements').find('a.round-button.load-more');

if($engagements.length > 0) {
    $engagements.each(function(index) {
        setTitle($engagements.eq(index).find('h2'));
    });
    $moreButton.click(function(e) {
        e.preventDefault();
        $engagements.each(function(index) {
            if(!$engagements.eq(index).hasClass('show')) {
                $engagements.eq(index).addClass('show');
                setTitle($engagements.eq(index).find('h2'));
            }
        });
        $(this).addClass('hide');
    });

    $engagements.hover(function () {
        $engagements.addClass('fade-down');
        $(this).removeClass('fade-down');
        $(this).addClass('scale');
    }, function () {
        $engagements.removeClass('fade-down');
        $(this).removeClass('scale');
    });
}