import Glide from "@glidejs/glide";
import {gsap} from 'gsap';
let timeoutAnim = 0;
let nextSlideTimeout = 0;
let animInTimeout = 0;

setTimeout(function() {
    if($('#content-section .gsap-anim-slides').length > 0) {
        gsap.set('#content-section .gsap-anim-slides .anim-container span', {autoAlpha: 0, y: '100%', rotate: '4deg', z: 0});
    }
}, 50);

if($('.hero a.round-button').length > 0) {
    gsap.set($('.hero a.round-button'), {scale: 0, y: 0, autoAlpha: 1});
    gsap.set($('.hero a.round-button .anim-left'), {maxWidth: 0});
    gsap.set($('.hero a.round-button .anim-right'), {maxWidth: 0});
}

if($('.hero.front-page-video').length > 0) {
    let $videoSrc;
    let $videoElem;
    if($(window).width() < 1024 && $('.hero').find('video.mobile-video').length > 0) {
        $videoElem = $('.hero').find('video.mobile-video');
        $videoSrc = $videoElem.data('src');
    }
    else {
        $videoElem = $('.hero').find('video.desktop-video');
        $videoSrc = $videoElem.data('src');
    }
    let source = createSourceElement($videoSrc);
    $videoElem[0].appendChild(source);
}

function createSourceElement(src) {
    const source = document.createElement('source');
    source.type = 'video/mp4';
    source.src = src;
    return source;
}

function initImagesSlider(id, size, post_type, random, front_page) {
    let startSlide;
    let frontPage = false;
    const imagesCount = $('.glide__slides .glide__slide:not(.glide__slide--clone)').length;
    if(random == '1') {
        startSlide = Math.floor(Math.random() * imagesCount);
    }
    else {
        startSlide = 0;
    }   
    if(front_page) {
        frontPage = true;
    }

    if (size == 'fullwidth' && post_type == 'post') {
        var imagesGlide = new Glide('#' + id, {
            type: 'carousel',
            keyboard: false,
            startAt: startSlide,
            perView: 1,
            gap: 0,
            autoplay: 11500,
            animationDuration: 2000,
            animationTimingFunc: 'cubic-bezier(0.465, 0.840, 0.440, 1.000)'
        });
    }
    else if(frontPage) {
        var imagesGlide = new Glide('#' + id, {
            type: 'carousel',
            keyboard: false,
            startAt: 0,
            perView: 1,
            gap: 0,
            autoplay: 10000,
            animationDuration: 2000,
            animationTimingFunc: 'cubic-bezier(0.465, 0.840, 0.440, 1.000)'
        });
    }
    else if (size == 'fullwidth' || size == 'right') {  
        var imagesGlide = new Glide('#' + id, {
            type: 'carousel',
            keyboard: false,
            startAt: startSlide,
            perView: 1,
            gap: 0,
            autoplay: false,
            animationDuration: 0,
        });
    }
    else {
        var imagesGlide = new Glide('#' + id, {
            type: 'carousel',
            keyboard: false,
            startAt: startSlide,
            perView: 1,
            gap: 0,
            breakpoints: {
                767: { gap: 0, perView: 1.25 },
                1023: { gap: 16, perView: 1.3 },
                1439: { gap: 0, perView: 1 }
            },
            autoplay: 12000,
            animationDuration: 2000,
            animationTimingFunc: 'cubic-bezier(0.465, 0.840, 0.440, 1.000)'
        });
    }

    imagesGlide.on('mount.after', function () {
        var $activeSlide = $('.hero .glide .glide__slide--active');
        if($activeSlide.find('video').length > 0) {
            var activeVideo = $activeSlide.find('video').get(0);
            activeVideo?.play();
        }
        var glideNavigation = images.querySelector('.glide-navigation');
        var items = glideNavigation.querySelectorAll('a');
        items[imagesGlide.index].classList.add('active');

        animInTimeout = setTimeout(function() {
            animInText();
        }, 300);
    });

    imagesGlide.on('run.before', function() {
        animOutText(images.querySelectorAll('.glide__slide--active'));
    });

    imagesGlide.on('run.after', function () {
        animInText();
    });       

    function animOutText(elem) {
        gsap.to(elem[0].querySelectorAll('h1 .anim-container span'), {
            duration: 2,
            autoAlpha: 0,
            y: '-100%',
            ease: 'power3.inOut',
        });
        if(elem[0].querySelectorAll('a.round-button').length > 0) {
            gsap.to(elem[0].querySelectorAll('a.round-button'), {
                duration: 2,
                autoAlpha: 0,
                y: '-200%',
                ease: 'power3.inOut',
            });
            gsap.to(elem[0].querySelectorAll('a.round-button .anim-left'), {
                duration: 2,
                ease: 'power1',
            });
            gsap.to(elem[0].querySelectorAll('a.round-button .anim-right'), {
                duration: 2,
                ease: 'power1',
            });
        }
    }

    function animInText() {
        var $activeSlide = $('.hero .glide .glide__slide--active');
        if($activeSlide.find('video').length > 0 && !$('.hero').hasClass('front-page-video')) {  
            var $slidesNotActive = $('.hero .glide .glide__slide:not(.glide__slide--clone, .glide__slide--active)');
            if($slidesNotActive.length > 0) {
                $slidesNotActive.each(function(index) {
                    if($slidesNotActive.eq(index).find('video').length > 0) {
                        $slidesNotActive.eq(index).find('video').get(0).pause();
                        $slidesNotActive.eq(index).find('video').get(0).currentTime = 0;
                    }
                });
            }
            var activeVideo = $activeSlide.find('video').get(0);
            activeVideo.pause();
            activeVideo.currentTime = 0;
            activeVideo?.play();
        }
        var $slides = $('.hero .glide .glide__slide');
        if($slides.length > 0) {
            $slides.each(function() {
                gsap.set($(this).find('h1 .anim-container span'), {autoAlpha: 0, y: '100%', rotate: '4deg', z: 0});
                if($(this).find('a.round-button').length > 0) {
                    gsap.set($(this).find('a.round-button'), {scale: 0, y: 0, autoAlpha: 1});
                    gsap.set($(this).find('a.round-button .anim-left'), {maxWidth: 0});
                    gsap.set($(this).find('a.round-button .anim-right'), {maxWidth: 0});
                }
            });
        }
        gsap.to(images.querySelectorAll('.glide__slide--active h1 .anim-container span'), {
            duration: 2,
            autoAlpha: 1,
            y: 0,
            rotate: 0,
            z: 0,
            stagger: 0.2,
            ease: 'power3.inOut',
        });
        if($('.glide__slide--active a.round-button').length > 0) {
            gsap.set('.glide__slide--active a.round-button', {scale: 0, y: 0, autoAlpha: 1});
            gsap.to('.glide__slide--active a.round-button', {
                duration: 1,
                scale: 1,
                delay: 1.25,
                ease: 'power1',
            });
            gsap.to('.glide__slide--active a.round-button .anim-left', {
                duration: 1.5,
                maxWidth: '2rem',
                delay: 1.75,
                ease: 'power1',
            });
            gsap.to('.glide__slide--active a.round-button .anim-right', {
                duration: 1.5,
                maxWidth: '2rem',
                delay: 1.75,
                ease: 'power1',
            });
        }
    }

    var images = document.getElementById(id);
    if (images && images.querySelector('.glide__slides').children.length > 1) {
        var glideNavigation = images.querySelector('.glide-navigation');

        document.addEventListener('keyup', function(e) {
            if (e.key == 'ArrowLeft') {
                if($('body').hasClass('home')) {
                    setTimeout(function() {
                        imagesGlide.go('<');
                    }, 1500);
                }
                else {
                    imagesGlide.go('<');
                }
             }
             else if (e.key == 'ArrowRight') {
                if($('body').hasClass('home')) {
                    setTimeout(function() {
                        imagesGlide.go('>');
                    }, 1500);
                }
                else {
                    imagesGlide.go('>');
                }
             }
        });

        if (glideNavigation) {
            imagesGlide.on('run', function (e) {
                var items = glideNavigation.querySelectorAll('a');
                items.forEach(function (item) {
                    item.classList.remove('active');
                });

                items[imagesGlide.index].classList.add('active');
            });

            glideNavigation.querySelectorAll('a').forEach(function (link) {
                link.addEventListener('click', function (e) {
                    var elem = this;
                    e.preventDefault();
                    imagesGlide.go('=' + elem.getAttribute('data-id'));
                });
            });
        }
        imagesGlide.mount();
    }
}

window.initImagesSlider = initImagesSlider;

export const setTitle = function ($element) {
    if ($element.hasClass('no-js-split') && $('body').hasClass('home')) {
        return;
    }
    else if($element.hasClass('no-js-split') && $(window).width() >= 768) {
        return;
    }

    var $title = $element.text();
    $element.empty();
    $.each($title.split(/\s+/), function () {
        var $span = $("<span>" + this + "</span>");
        $element.append($span);
    });
    var $containerWidth = $element.innerWidth();
    var $string = '';
    var $totalWidth = 0;
    var $spans = $element.find('span');
    var $space;
    if($(window).width() < 1024) {
        $space = 16;
    }
    else if($(window).width() < 2000) {
        $space = 24;
    }
    else if($(window).width() < 2560) {
        $space = 40;
    }
    else {
        $space = 40;
    }
    for(var i=0; i<$spans.length; i++) {
        $totalWidth += ($spans.eq(i).outerWidth() + $space);
        if ($totalWidth <= ($containerWidth)) {
            $string += $spans.eq(i).text() + ' ';
        }
        else {
            $string += '</br>' + $spans.eq(i).text() + ' ';
            $totalWidth = ($spans.eq(i).outerWidth() + $space);
        }
    }
    $element.empty();
    $.each($string.split("</br>"), function () {
        var $line = $("<span class='anim-container'><span>" + this + "</span></span>");
        $element.append($line);
    });
}
if($('.hero').find('.glide').length > 0) {
    let $titles = $('.hero').find('.glide h1');
    $titles.each(function(index) {
        setTitle($titles.eq(index));
    });
    setTimeout(function() {
        $titles.each(function(index) {
            $titles.eq(index).css({
                'opacity': 1
            });
        });
    },50);
}
else if($('.hero').find('.images-wrapper').length > 0) {
    setTimeout(function() {
        $('.hero').find('.images-wrapper h1').css({
            'opacity': 1
        })
    },50);
    setTitle($('.hero').find('.images-wrapper h1'));
}

setTimeout(function() {
    if($('.hero').length > 0) {
        if($('body:not(.home) .hero').find('h1 span').length/2 > 2) {
            $('body:not(.home) .hero').find('h1').addClass('smaller-font'); 
        }
    }
}, 100);

if($('.hero .continue-indicator').length > 0) {
    $('.hero .continue-indicator').addClass('anim-bump');
    setTimeout(function() {
        $('.hero .continue-indicator').removeClass('anim-bump');
    }, 3000);
    $('.hero .continue-indicator a').click(function (e) {
        if ($(this).attr('href') === '#') {
            e.preventDefault();

            var $this = $(this);
            var $parent = $this.parents('.hero');
            var $next = $parent.next('*');
            let offset = 35;

            if ($(window).width() < 1024) {
                offset = 50;
            }

            if ($next.length > 0) {
                $('html, body').animate({
                    scrollTop: $next.offset().top - offset
                }, 400);
            }
        }
    });
}

if ($(window).width() < 1024) {
    setTimeout(function () {
        let $glideSlide = $('.glide.position-right');
        let $glideSlideFullwidth = $('.glide.position-fullwidth');
        let $imageWrapper = $('.glide').find('.image-wrapper').first();
        if ($glideSlide.length > 0 || $glideSlideFullwidth.length > 0) {
        /*     if ($(window).width() < 768 && $('.glide').hasClass('front-page')) {
                $('.glide .glide-navigation').css({
                    'top': 'initial',
                    'bottom': '4rem'
                });
            }
            else  */if ($(window).width() < 768) {
                $('.glide .glide-navigation').css({
                    'top': 'initial',
                    'bottom': '2rem'
                });
            }
            else if ($(window).width() < 1024 && $('.glide').hasClass('front-page')) {
                $('.glide .glide-navigation').css({
                    'top': 'initial',
                    'bottom': '6rem'
                });
            }
            else {
                $('.glide .glide-navigation').css({
                    'top': 'initial',
                    'bottom': '3rem'
                });
            }
        }
        else if ($imageWrapper.length > 0) {
            $('.glide .glide-navigation').css({
                'top': $imageWrapper.height() + 32
            });
        }
    }, 100);
    setTimeout(function(){
        $('.glide .glide-navigation').css({
            'opacity': 1
        });  
    }, 200);
}

$(window).resize(function() {
    if($('.hero').find('.images-wrapper').length > 0) {
        setTitle($('.hero').find('.images-wrapper h1'));
        $('.hero').find('.images-wrapper h1 span').css({
            'opacity': 1
        })
    }
});

if($('.shape-icon').length > 0) {
const viewPort = $(window).height();
$(window).scroll( function() {
	var scroll = $(window).scrollTop();
    var speedRotate = 0.05;
    
	$('.shape-icon').each(function(index){
        var $this = $(this);
        var topOffset = $this.offset().top - viewPort;
        var height = $this.outerHeight();
        var rotateSize = (scroll - (topOffset < 0 ? 0 : topOffset)) * speedRotate;
        
        // Prevent parallax when scrolled past elememt
        if(scroll > topOffset + viewPort + height) {
            return;
        }
		
        $this.css({ 
            'transform': scroll >= topOffset ? ('rotate(' + rotateSize + 'deg)' ) : ''
        });
    }); 
    if($(window).width() < 768) {
        if(scroll > 15) {
            $('.hero .links').css({
                'position' : 'absolute',
            });
            $('.hero .glide .glide__track .glide__slides .glide__slide .links').css({
                'left' : 0
            });
        }
        else {
            $('.hero .links').css({
                'position' : 'fixed',
            });
            $('.hero .glide .glide__track .glide__slides .glide__slide .links').css({
                'left' : '-0.6rem'
            });
        }
    }
}); 
}
