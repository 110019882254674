let offset;
if($(window).width() < 1024) {
    offset = -130;
}
else {
    offset = -150;
}

if($('body.single .continue-indicator').length > 0) {
    $('body.single .continue-indicator').addClass('anim-bump');
    setTimeout(function() {
        $('body.single .continue-indicator').removeClass('anim-bump');
    }, 3000);
}

$('.single-post .continue-indicator a').click(function(e) {
    e.preventDefault();

    var $this = $(this);
    var $parent = $this.parents('.image');
    var $next = $parent.next('*');

    if ($next.length > 0) {
        $('html, body').animate({
            scrollTop: $next.offset().top + offset
        }, 400);
    }
});

$('.single-engagement .continue-indicator a').click(function(e) {
    e.preventDefault();

    var $this = $(this);
    var $parent = $this.parents('.article-hero');
    var $next = $parent.next('*');

    if ($next.length > 0) {
        $('html, body').animate({
            scrollTop: $next.offset().top + offset
        }, 400);
    }
});

setTimeout(function() {
    if($('.single-investment #content-section .icon-wrapper').length > 0) {
        $('.icon-wrapper').each(function(index) {
            if(index == 0) {
                $(this).css({
                    'left': '10%',
                    'width': '12vw',
                    'height': '12vw'
                });
                if($(window).width() >= 1024) {
                    $(this).css({
                        'top': '40%',
                        'left': '10%',
                        'width': '10vw',
                        'height': '10vw'
                    });
                }
            }
            else if (index == 1 && $(window).width() >= 1024){
                $(this).css({
                    'top': '105%'
                })
            }
            $(this).addClass('animate');
        });
    }
}, 100);

$(window).scroll( function() {
	var scroll = $(window).scrollTop();
    if($(window).width() < 768) {
        if(scroll > 15) {
            $('.post-type-engagement .article-hero .continue-indicator').css({
                'position' : 'absolute'
            });
        }
        else {
            $('.post-type-engagement .article-hero .continue-indicator').css({
                'position' : 'fixed'
            });
        }
    }
}); 