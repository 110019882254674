import { markerClick } from "./_offices";


var styles = [
    {
        "featureType": "all",
        "elementType": "all",
        "stylers": [
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "saturation": "-100"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "saturation": 36
            },
            {
                "color": "#000000"
            },
            {
                "lightness": 40
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "color": "#000000"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "all",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#818b93"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#818b93"
            },
            {
                "lightness": 17
            },
            {
                "weight": 1.2
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#818b93"
            },
            {
                "lightness": 20
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#a0a7ad"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#a0a7ad"
            }
        ]
    },
    {
        "featureType": "landscape.natural",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#a0a7ad"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "lightness": 21
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#969DA4"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#969DA4"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "color": "#818b93"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#818b93"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#818b93"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#818b93"
            },
            {
                "lightness": 29
            },
            {
                "weight": 0.2
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#818b93"
            },
            {
                "lightness": 18
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#818b93"
            }
        ]
    },
    {
        "featureType": "road.arterial",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#818b93"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#818b93"
            },
            {
                "lightness": 16
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#818b93"
            }
        ]
    },
    {
        "featureType": "road.local",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#818b93"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#818b93"
            },
            {
                "lightness": 19
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#818b93"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry",
        "stylers": [
            {
                "color": "#818b93"
            },
            {
                "lightness": 17
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#818b93"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#818b93"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
];

export function initMap() {
    if (!google || !google.maps) {
        return;
    }

    document.querySelector('header').classList.add('map-header');
    var maps = document.querySelectorAll('.map');

    for (var i = 0; i < maps.length; i++) {
        var mapContainer = maps[i];
        var lat = parseFloat(mapContainer.getAttribute('data-lat')) || 0;
        var lng = parseFloat(mapContainer.getAttribute('data-lng')) || 0;
        var locations = maps[i].querySelectorAll('.marker');

        if (!lat || !lng) {
            var lat = parseFloat(locations[0].getAttribute('data-lat'));
            var lng = parseFloat(locations[0].getAttribute('data-lng'));
        }

        var position = {
            lat: lat,
            lng: lng
        };

        var zoom;
        if (locations.length == 0) {
            zoom = 13;
        }
        else {
            zoom = 5.5;
        }

        var controlPos;
        var showMapType;
        if($(window).width() < 1024) {
            controlPos = google.maps.ControlPosition.BOTTOM_CENTER;
        }
        else {
            controlPos = google.maps.ControlPosition.BOTTOM_RIGHT;
        }

        var options = {
            center: position,
            zoom: zoom,
            minZoom: zoom - 3,
            restriction: {
                latLngBounds: {
                  east: 179.9999,
                  north: 85,
                  south: -85,
                  west: -179.9999
                },
                strictBounds: true
              },
            styles: styles,
            showMapType: true,
            streetViewControl: false,
            mapTypeControl: showMapType,
            mapTypeControlOptions: {
                style: google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                position: controlPos,
                mapTypeIds: ["satellite", "roadmap"],
              },
              zoomControlOptions: {
                position: controlPos,
              },
            scrollwheel: false,
            fullscreenControl: false,
        };

        var map = new google.maps.Map(mapContainer, options);

        var marker, j;

        if (locations.length == 0) {

            var marker = new google.maps.Marker({
                position: position,
                map: map,
                icon: "/wp-content/uploads/2022/01/circle-grey.png",
                label: {
                    text: locations[0].getAttribute('data-name'),
                    className: 'marker-label'
                }
            });
            google.maps.event.addListener(marker, 'click', function () {
                markerClick(locations[0].getAttribute('data-slug'));
            });
        }
        else {
            var bounds = new google.maps.LatLngBounds();
            for (j = 0; j < locations.length; j++) {
                let slug = locations[j].getAttribute('data-slug');
                let labelClass = 'marker-label-' + j;
                let labelText = locations[j].getAttribute('data-name');
                let markerIndex = j;
                marker = new google.maps.Marker({
                    position: new google.maps.LatLng(locations[j].getAttribute('data-lat'), locations[j].getAttribute('data-lng')),
                    map: map,
                    icon: "/wp-content/uploads/2022/01/circle-grey.png",
                    label: {
                        text: labelText,
                        className: 'marker-label marker-label-' + j
                    }
                });
                var latlng = new google.maps.LatLng(locations[j].getAttribute('data-lat'), locations[j].getAttribute('data-lng'));
                bounds.extend(latlng);

                bindClick(marker, slug, labelClass, markerIndex);
            }
            google.maps.event.addListener(map, 'zoom_changed', function() {
                var zoomChangeBoundsListener = 
                    google.maps.event.addListener(map, 'bounds_changed', function(event) {
                        if($(window).width() < 768 && this.getZoom() > 4.5 && this.initialZoom == true) {
                            this.setZoom(4.5);
                            this.initialZoom = false;
                        }
                        if (this.getZoom() > 5.5 && this.initialZoom == true) {
                            this.setZoom(5.5);
                            this.initialZoom = false;
                        }
                    google.maps.event.removeListener(zoomChangeBoundsListener);
                });
            });
            setTimeout(function() {
                google.maps.event.addListener(map, 'maptypeid_changed', function() {
                    if(this.mapTypeId == 'satellite') {
                        $('.map .gmnoprint[role="menubar"] .gm-style-mtc').addClass('show');
                        $('.map .gmnoprint[role="menubar"] .gm-style-mtc').first().removeClass('show');
                    }
                    else {
                        $('.map .gmnoprint[role="menubar"] .gm-style-mtc').removeClass('show');
                        $('.map .gmnoprint[role="menubar"] .gm-style-mtc').first().addClass('show');
                    }
                });
            }, 1500);
            map.initialZoom = true;
            map.fitBounds(bounds);
        }
        function bindClick(marker, slug, labelClass, markerIndex) {
            google.maps.event.addListener(marker, 'click', function () {
                markerClick(slug);
            });
            google.maps.event.addListener(marker, 'mouseover', function () {
                marker.setIcon('/wp-content/uploads/2022/02/circle-blue-45.png');
                let currLabel = document.querySelector('.' + labelClass);
                currLabel.classList.add('hovered');
                setTimeout(function() {
                    document.querySelector('.marker-icon-' + markerIndex).classList.add('hovered');
                }, 100);
            });
            google.maps.event.addListener(marker, 'mouseout', function () {
                marker.setIcon('/wp-content/uploads/2022/01/circle-grey.png');
                let currLabel = document.querySelector('.' + labelClass);
                currLabel.classList.remove('hovered');
                setTimeout(function() {
                    document.querySelector('.marker-icon-' + markerIndex).classList.remove('hovered');
            }, 100);
            });
        }
    }
}

let margin;
if ($(window).width() < 768) {
    margin = 24;
}
else {
    margin = 32;
}
setTimeout(function () {
    let $markerIcons = $('.map').find('img[src="/wp-content/uploads/2022/01/circle-grey.png"]');
    $markerIcons.each(function(index) {
        $(this).parent().addClass('marker-icon marker-icon-' + index);
    });
    if($(window).width() < 768) {
        $('.marker-label').each(function () {
            $(this).css({
                'opacity': 1,
                'top': '40px'
            })
        });
    }
    else {
        $('.marker-label').each(function () {
            $(this).css({
                'opacity': 1,
                'left': '-' + (($(this).width() / 2) + margin) + 'px'
            })
        });
    }
}, 600);

setTimeout(function() {
    $('.map .gmnoprint[role="menubar"] .gm-style-mtc').first().addClass('show');
    let $mapButtonFirst = $('.map .gmnoprint[role="menubar"] .gm-style-mtc').first();
    let $mapButtonLast = $('.map .gmnoprint[role="menubar"] .gm-style-mtc').last();
    $mapButtonFirst.find('button').text('Satellite');
    $mapButtonLast.find('button').text('Map');
}, 1500);