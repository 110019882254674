import Glide from "@glidejs/glide";

function initSlideshow(id) {
var slideshow = new Glide('#' + id, {
    type: 'carousel',
    keyboard: true,
    startAt: 0,
    perView: 1,
    gap: 0,
    autoplay: 10000,
    animationDuration: 1000,
    animationTimingFunc: 'cubic-bezier(0.465, 0.840, 0.440, 1.000)'
});

    let images = document.getElementById(id);
    if (images && images.querySelector('.glide__slides').children.length > 1) {
        slideshow.mount();
        let glideNavigation = images.querySelector('.glide-navigation');

        if (glideNavigation) {
            slideshow.on('run', function (e) {
                let items = glideNavigation.querySelectorAll('a');
                items.forEach(function (item) {
                    item.classList.remove('active');
                });
                items[slideshow.index].classList.add('active');
            });

            glideNavigation.querySelectorAll('a').forEach(function (link) {
                link.addEventListener('click', function (e) {
                    e.preventDefault();
                    slideshow.go('=' + this.getAttribute('data-id'));
                });
            });
        }
    }
}

window.initSlideshow = initSlideshow;